<template>
    <div class="main">
        <div class="header">
            <div class="header-search">
                <van-search
                        readonly
                        v-model="fplxvalue"
                        show-action
                        placeholder="请选择发票类型"
                        @click="fplxshow=true"
                        shape="round"
                        style="padding-left:1px;"
                >
                    <template #right-icon>
                        <img style="width: 15px;" src="../../assets/img/invoice_xl@3x.png"/>
                    </template>

                    <template #action>
                        <div style="width: 45px; color: white; text-align: center; background: #307bf7;border-radius:0 5px 5px 0" @click.stop="showpop=true">
                            筛选
                        </div>
                    </template>
                </van-search>
            </div>
        </div>

        <div class="fptjsj" style="display:none;">
            <van-cell center >
                <!-- 使用 title 插槽来自定义标题 -->
                <template #title>
                    <div class="titleContent">
                        <div class="qjListItem">
                            <span class="spanTitle">开票数量：</span>
                            <span>6</span>
                        </div>
                        <div class="qjListItem">
                            <span class="spanTitle">价税合计：</span>
                            <span>87375</span>
                        </div>
                        <div class="qjListItem">
                            <span class="spanTitle">合计金额：</span>
                            <div>86509.91</div>
                        </div>
                        <div class="qjListItem">
                            <span class="spanTitle">合计税额：</span>
                            <span>865.09</span>
                        </div>
                    </div>
                </template>
            </van-cell>
        </div>
        <div class="list" v-if="!errstate" >
            <van-pull-refresh  v-model="state.refreshing" @refresh="onRefresh">
                <van-list
                        v-model:loading="state.loading"
                        :finished="state.finished"
                        finished-text="没有更多了"
                        @load="onLoad"
                >
                    <van-collapse v-model="activeNames" center style="border-radius: 10px;">
                        <div class="list-item" v-for="(item, index) in state.list" :key="item" :name=index >
                            <van-collapse-item :is-link=false style="border-radius: 10px;">
                                <template #title>
                                    <div class="titleContent">
                                        <div class="qjListItem">
                                            <span class="spanTitle">票种：</span>
                                            <span class="spanValue">{{getfplxmc(item.InvoiceType)}}</span>
                                        </div>
                                        <div class="qjListItem">
                                            <span class="spanTitle">开票金额：</span>
                                            <span class="spanValue">{{item.TotalAmount}}</span>
                                        </div>
                                        <div class="qjListItem">
                                            <span class="spanTitle">发票状态：</span>
                                            <div class="spanValue">{{getfpztByStatues(item.State)}}
                                            </div>
                                        </div>
                                        <div class="qjListItem">
                                            <span class="spanTitle">开票时间：</span>
                                            <span class="spanValue">{{item.czsj}}</span>
                                        </div>
                                    </div>
                                </template>
                                <div class="list_flex">
                                    <div @click="fpckClick(item)">
                                        <img style="width: 36px" src="../../assets/img/invoice_ck@3x.png"/>
                                    </div>
                                    <div @click="zfClick(item, index)" v-if="item.bz_zf=='1'">
                                        <img style="width: 36px" src="../../assets/img/invoice_zf@3x.png"/>
                                    </div>
                                    <div @click="hcClick(item, index)" v-if="item.bz_hc=='1'">
                                        <img style="width: 36px" src="../../assets/img/invoice_hc@3x.png"/>
                                    </div>
                                </div>
                            </van-collapse-item>
                        </div>
                        </van-collapse>
                </van-list>
            </van-pull-refresh>
        </div>
        <van-empty image="error" v-if="errstate" :description=description />
        <van-popup v-model:show="fplxshow" round position="bottom">
            <van-picker
                    show-toolbar
                    :columns="fplx"
                    @cancel="fplxshow = false"
                    @confirm="onConfirmFPLX"
            />
        </van-popup>
        <van-popup v-model:show="showpop" position="right" :style="{ width: '90%', height: '100%' }">
            <div>
                <van-cell-group>
                    <!--<van-cell center is-link @click="skpbhshow=true">-->
                        <!--<template #title>-->
                            <!--<van-field readonly v-model="cxtj.skpbh" placeholder="点击选择税控盘" label="税盘编号"/>-->
                        <!--</template>-->
                    <!--</van-cell>-->
                    <!--<cell center is-link @click="fpzlhshow=true">-->
                    <!--<template #title>-->
                    <!--<field readonly v-model="cxtj.fpzl" placeholder="点击选择发票种类" label="发票种类"/>-->
                    <!--</template>-->
                    <!--</cell>-->
                    <van-cell center is-link @click="kpsjshow=true;  selectIndex = 0">
                        <template #title>
                            <van-field readonly v-model="cxtj.kprq_q" right-icon="calendar-o" placeholder="点击选择开票时间起"
                                       label="开票时间起"/>
                        </template>
                    </van-cell>
                    <van-cell center is-link @click="kpsjshow=true;  selectIndex = 1">
                        <template #title>
                            <van-field readonly v-model="cxtj.kprq_z" right-icon="calendar-o" placeholder="点击选择开票时间止"
                                       label="开票时间止"/>
                        </template>
                    </van-cell>
                    <!--<div class="actionBtn">-->
                        <!--<van-button class="btn" :type=" rqactive=== 1?'primary': 'default'" @click="rqClick(1)">今天-->
                        <!--</van-button>-->
                        <!--<van-button class="btn" :type=" rqactive=== 2?'primary': 'default'" @click="rqClick(2)">昨天-->
                        <!--</van-button>-->
                        <!--<van-button class="btn" :type=" rqactive=== 3?'primary': 'default'" @click="rqClick(3)">本月-->
                        <!--</van-button>-->
                        <!--<van-button class="btn" :type=" rqactive=== 4?'primary': 'default'" @click="rqClick(4)">上月-->
                        <!--</van-button>-->
                    <!--</div>-->
                    <van-cell center is-link @click="fpztshow=true">
                        <template #title>
                            <van-field readonly v-model="fpztValue" placeholder="点击选择发票状态" label="发票状态"/>
                        </template>
                    </van-cell>
                    <!--<van-cell center>-->
                        <!--<template #title>-->
                            <!--<van-field v-model="cxtj.fpje" label="开票金额" placeholder="请输入开票金额">-->
                                <!--<template #button>-->
                                    <!--<van-dropdown-menu style="width: 70px">-->
                                        <!--<van-dropdown-item style="width: 70px" v-model="cxtj.fpjezt"-->
                                                           <!--:options="optionfpjezt"/>-->
                                    <!--</van-dropdown-menu>-->
                                <!--</template>-->
                            <!--</van-field>-->
                        <!--</template>-->
                    <!--</van-cell>-->
                    <van-cell center>
                        <template #title>
                            <van-field v-model="cxtj.nsrsbh" placeholder="请填写购方税号" label="购方税号"/>
                        </template>
                    </van-cell>
                    <van-cell center>
                        <template #title>
                            <van-field v-model="cxtj.nsrmc" placeholder="请填写购方名称" label="购方名称"/>
                        </template>
                    </van-cell>
                    <!--<van-cell center is-link @click="fplyshow=true">-->
                        <!--<template #title>-->
                            <!--<van-field readonly v-model="cxtj.fply" placeholder="点击选择发票发票来源" label="发票来源"/>-->
                        <!--</template>-->
                    <!--</van-cell>-->
                    <!--<van-cell center>-->
                        <!--<template #title>-->
                            <!--<van-field v-model="cxtj.fpdm" placeholder="请填写发票代码" label="发票代码"/>-->
                        <!--</template>-->
                    <!--</van-cell>-->
                    <!--<van-cell center>-->
                        <!--<template #title>-->
                            <!--<van-field v-model="cxtj.fphmq" placeholder="请填写发票代码起" label="发票号码起"-->
                                       <!--:error-message="cxtj.fphmq.length < 8? '请输入8位发票起始号码': ''"/>-->
                        <!--</template>-->
                    <!--</van-cell>-->
                    <!--<van-cell center>-->
                        <!--<template #title>-->
                            <!--<van-field v-model="cxtj.fphmz" placeholder="请填写发票代码止" label="发票代码止"-->
                                       <!--:error-message="cxtj.fphmz.length < 8? '请输入8位发票终止号码': ''"/>-->
                        <!--</template>-->
                    <!--</van-cell>-->


                </van-cell-group>

                <div class="bottom">
                    <!-- <van-button class="btn bottombtn" type="primary" @clik="czAction()">重 置</van-button> -->
                    <van-button class=" bottombtn" block type="primary" @click="showpop=false; cxsxCik()">查 询</van-button>
                </div>
            </div>
        </van-popup>
        <van-popup v-model:show="skpbhshow" round position="bottom">
            <van-picker
                    show-toolbar
                    :columns="columnsskp"
                    @cancel="skpbhshow = false"
                    @confirm="onConfirm"
            />
        </van-popup>
        <van-popup v-model:show="fpzlhshow" round position="bottom">
            <van-picker
                    show-toolbar
                    :columns="fplx"
                    @cancel="fplycolumn = false"
                    @confirm="onConfirmFP"
            />
        </van-popup>
        <van-popup v-model:show="kpsjshow" round position="bottom">
            <van-datetime-picker
                    v-model="currentDate"
                    type="date"
                    title="选择年月日"
                    :min-date="minDate"
                    :max-date="maxDate"
                    @confirm="onConfirmFPSJ"
            />
        </van-popup>
        <van-popup v-model:show="fpztshow" round position="bottom">
            <van-picker
                    show-toolbar
                    :columns="fpzt"
                    @cancel="fpztshow = false"
                    @confirm="onConfirmfpzt"
            />
        </van-popup>
        <van-popup v-model:show="fplyshow" round position="bottom">
            <van-picker
                    show-toolbar
                    :columns="fplycolumn"
                    @cancel="fplyshow = false"
                    @confirm="onConfirmfply"
            />
        </van-popup>


    </div>
</template>

<script>

    import {defineComponent, reactive, ref, getCurrentInstance} from 'vue'
    import config from "@/config/"
    import request from '@/api/invoice.js'
    import fplx from '@/assets/json/fplx.json'
    import fpzt from '@/assets/json/fpzt.json'
    import {Dialog, Notify} from "vant"
    import common from '@/utils/common.js'

    export default defineComponent({
        name: "invoiceQuery",
        setup() {
            const {proxy} = getCurrentInstance();
            const fplxvalue = ref("");
            const activeNames = ref([]);
            const state = reactive({
                list: [],
                loading: false,
                finished: false,
                refreshing: false,
            });
            const errstate = ref(false);
            const fplxshow = ref(false);
            const showpop = ref(false);
            const cxtj = reactive({
                InvoiceType: "",
                kprq_q: "",
                kprq_z: "",
                State: "",
                nsrsbh:"",
                nsrmc: "",
                // skpbh: "",
                // fpzl: "",
                // kpsj: "",
                // fpjezt: 0,
                // fpje: "",
                // fply: "",
                // fpdm: "",
                // fphmq: "",
                // fphmz: ""
            });
            const fpztValue = ref("");
            const skpbhshow = ref(false);
            const columnsskp = ref(["589918552612"]);
            const fpzlhshow = ref(false);
            const kpsjshow = ref(false);
            const minDate = ref(new Date(2020, 0, 1));
            const currentDate = ref(new Date());
            const maxDate = ref(new Date(2025, 10, 1));
            const selectIndex = ref(0);
            const rqactive = ref(0);
            const fpztshow = ref(0);
            const optionfpjezt = ref([
                {text: '>', value: -1},
                {text: '=', value: 0},
                {text: '<', value: 1},
            ]);
            const fplyshow = ref(false);
            const fplycolumn = ref(["全部", "平台开具", "发票修复", "三方导入"]);
            const page = ref("1");
            const description = ref("");
            const pageSize = ref(config.request.pageSize);
            const onConfirmFPLX = (value)=>{
                console.log(value)
                fplxvalue.value = value.text;
                fplxshow.value = false;
                if(value.value==0){
                    cxtj.InvoiceType="";
                }else{
                    cxtj.InvoiceType = value.value.toString();
                }
                // onLoad();
                cxsxCik();
            };
            const onConfirm = (value) => {
                cxtj.skpbh = value;
                skpbhshow.value = false;
            };
            const onConfirmFP = (value) => {
                cxtj.fpzl = value;
                fpzlhshow.value = false;
            };
            const onConfirmFPSJ = (value) => {
                // alert(value.toLocaleDateString());
                // alert(value.toLocaleDateString().replace(/\//g, '-'));
                if (selectIndex.value === 0) {
                    cxtj.kprq_q = value.toLocaleDateString().replace(/\//g, '-');
                    cxtj.kprq_q = riqizh(cxtj.kprq_q);
                } else {
                    cxtj.kprq_z = value.toLocaleDateString().replace(/\//g, '-');
                    cxtj.kprq_z = riqizh(cxtj.kprq_z);
                }
                
                kpsjshow.value = false;
            };
            const riqizh = (dateval)=> {
                console.log(dateval);
                dateval=dateval.replace('-','/');
                console.log(dateval);
                var aa = dateval.split("-")[0];
                console.log(aa);
                var nian = aa.split("/")[0];
                var yue = aa.split("/")[1];
                var ri = dateval.split("-")[1];
                if(yue < 10){
                    yue="0"+yue;
                }
                if(ri < 10){
                    ri="0"+ri;
                }
                var fhdate = nian+'-'+yue+'-'+ri;
                console.log(fhdate);
                return fhdate;
             }
            // const rqClick = (index) => {
            //     rqactive.value = index;
            //     var day = new Date();
            //     if (index === 1) {
            //         cxtj.kpsjq = day.toLocaleDateString().replace(/\//g, '-');
            //         cxtj.kpsjz = day.toLocaleDateString().replace(/\//g, '-');
            //     }
            //     if (index === 2) {
            //         day.setDate(day.getDate() - 1);
            //         cxtj.kpsjq = day.toLocaleDateString().replace(/\//g, '-');
            //         cxtj.kpsjz = day.toLocaleDateString().replace(/\//g, '-');
            //     }
            //     if (index === 3) {
            //         day.setDate(1);
            //         cxtj.kpsjq = day.toLocaleDateString().replace(/\//g, '-');
            //         day.setMonth(day.getMonth() + 1);//这时候day已经变成下个月第一天
            //         day.setDate(day.getDate() - 1);//下个月的第一天的前一天就是本月最后一天
            //         cxtj.kpsjz = day.toLocaleDateString().replace(/\//g, '-');
            //     }
            //     if (index === 4) {
            //         day.setMonth(day.getMonth() - 1);//这时候day已经变成下个月第一天
            //         day.setDate(1);
            //         cxtj.kpsjq = day.toLocaleDateString().replace(/\//g, '-');
            //         day.setMonth(day.getMonth() + 1);//这时候day已经变成下个月第一天
            //         day.setDate(day.getDate() - 1);//下个月的第一天的前一天就是本月最后一天
            //         cxtj.kpsjz = day.toLocaleDateString().replace(/\//g, '-');
            //     }
            // };
            const czAction = () => {

            };
            const onConfirmfpzt = (value) => {
                fpztValue.value = value.text;
                fpztshow.value = false;
                cxtj.State = value.value;
            };
            const onConfirmfply = (value) => {
                fplyshow.value = false;
                cxtj.fply = value
            }
            const onLoad = () => {
                // console.log("..........");
                searchMethod({
                    ...cxtj,
                    page: page.value,
                    pageSize: pageSize.value
                });

            };
            const cxsxCik = () => {
                searchMethod({
                    ...cxtj,
                    page: "1",
                    pageSize: pageSize.value
                });
            };
            
            const onRefresh = () => {
                // 清空列表数据
                state.finished = false;

                // 重新加载数据
                // 将 loading 设置为 true，表示处于加载状态
                state.loading = true;
                searchMethod({
                    ...cxtj,
                    page: "1",
                    pageSize: pageSize.value
                });
            };
            const searchMethod = (data) => {
                request.fp_cx_page(data).then(res => {
                    if (res.returnCode === "00") {
                        errstate.value = false;
                        console.log(data.page);
                        if(data.page == "1"){
                            state.list = [];
                            page.value = "1";
                        }
                        if (state.refreshing) {
                            state.refreshing = false;
                            page.value = "1";
                            state.list = [];
                        }
                        state.list.push(...res.rows.datas);
                        console.log(state.list);
                        state.loading = false; // 加载状态结束
                        page.value = Number(page.value) + 1;
                    
                        if (state.list.length >= res.rows.total) {
                            state.finished = true;
                        }
                    } else {
                        errstate.value = true;
                        description.value = res.returnMessage;
                    }
                });
            };
            const getfplxmc = (value) => {
                let obj = common.getObjfromArrByKey(fplx, value)
                return obj.text;
            };
            const zfClick = (item) => {
                Dialog.confirm({
                    title: config.commonMes.alertTitle,
                    message: '是否作废该发票信息？',
                }).then(() => {
                    request.fp_zf({id: item.id}).then(res => {
                        if (res.returnCode === "00"){
                            Notify({ type: 'success', message: res.returnMessage });
                        }else {
                            Notify({ type: 'danger', message: res.returnMessage });
                        }
                    onRefresh();
                    });
                })
                    .catch(() => {
                    });

            };
            const hcClick = (item) => {
                Dialog.confirm({
                    title: config.commonMes.alertTitle,
                    message: '是否红冲该发票信息？',
                }).then(() => {
                    request.fp_hc({id: item.id}).then(res => {
                        if (res.returnCode === "00"){
                            Notify({ type: 'success', message: res.returnMessage });
                        }else {
                            Notify({ type: 'danger', message: res.returnMessage });
                        }
                    onRefresh();
                    });
                })
                    .catch(() => {
                    });

            };
            const fpckClick = (item)=>{
                proxy.$root.$router.push({name: "invoiceDetail", params:{id: item.id, item: JSON.stringify(item)}})
            }
            const getfpztByStatues = (item)=>{
                let obj = common.getObjfromArrByKey(fpzt, item);
                return obj["text"];
            };
            return {
                fpztValue,
                cxtj,
                showpop,
                skpbhshow,
                columnsskp,
                fpzlhshow,
                fplx,
                kpsjshow,
                minDate,
                currentDate,
                maxDate,
                selectIndex,
                rqactive,
                fpztshow,
                fpzt,
                optionfpjezt,
                fplyshow,
                fplycolumn,
                activeNames,
                page,
                pageSize,
                state,
                description,
                errstate,
                fplxshow,
                fplxvalue,
                onConfirmFPLX,
                zfClick,
                onConfirm,
                onConfirmFP,
                onConfirmFPSJ,
                // rqClick,
                czAction,
                onConfirmfpzt,
                onConfirmfply,
                onLoad,
                onRefresh,
                getfplxmc,
                fpckClick,
                getfpztByStatues,
                cxsxCik,
                hcClick
            }
        }
    });
</script>

<style lang="less" scoped>
    .main {
        background: white;
        min-height: 100vh;
        padding: 10px;
        // overflow-y: auto;
        .drop-context{
            border-radius: 10px;
            overflow: hidden;
        }
    }
    .header {
        .header-search{
            width: 100%;
            background: white;
            // margin-left: 5%;
            margin-top: 20px;
            border-radius: 8px;
        }
    }
    .sx {
        width: 15%;
        height: 48px;
    }

    .actionBtn {
        margin: 10px;
    }

    .btn {
        margin: 8px;
    }

    .bottom {
        text-align: right;
        padding: 20px;
    }

    .fptjsj {
        margin: 10px;
        border: #eee solid 1px;
        box-shadow: 10px 5px 5px #000;
        border-radius: 8px;
    }

    .qjListItem {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .spanTitle {
        min-width: 80px;
    }
    .spanValue{
        color: #999;
    }

    .list_flex {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    .list-item{
        // margin:20px 10px;
        margin-top: 20px;
        // margin-left: 5%;
        width: 99%;
        border-radius: 10px;
        box-shadow:4px 4px 10px #eee; 
        border:1px solid #eee;
        overflow: hidden;
    }
    
    /deep/.van-search .van-search__action{
        padding: 0;
    }
    /deep/.van-search .van-search__content--round{
        border-radius: 5px 0 0 5px;
    }
</style>