import request from '../utils/request'

export default {
    fp_kp(data){
        return request({
            url: 'api/fpgl/fp_kp',
            method:'post',
            data
        })
    },
    fp_cx_page(data){
        return request({
            url: 'api/fpgl/fp_cx_page',
            method:'post',
            data
        })
    },
    fp_zf(data){
        return request({
            url: 'api/fpgl/fp_zf',
            method:'post',
            data
        })
    },
    fp_item_cx_list(data){
        return request({
            url: 'api/fpgl/fp_item_cx_list',
            method:'post',
            data
        })
    },
    fp_hc(data){
        return request({
            url: 'api/fpgl/fp_hc',
            method:'post',
            data
        })
    },
    fp_yl(data){
        return request({
            url: 'api/fpgl/fp_yl',
            method:'post',
            data
        })
    },
    fp_cx_list(data){
        return request({
            url: 'api/fpgl/fp_cx_list',
            method:'post',
            data
        })
    },
    fp_cx_mhsearch(data){
        return request({
            url: 'api/fpgl/qy_search',
            method:'post',
            data
        })
    }
}