export default {
    checkPhone: function (phone) {
        return (/^1(1|2|3|4|5|6|7|8|9)\d{9}$/.test(phone))
    },
    getUUID: function () {
        let s = [];
        let hexDigits = "0123456789abcdef";
        for (let i = 0; i < 36; i++) {
            s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        s[14] = "4";  // bits 12-15 of the time_hi_and_version field to 0010
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);  // bits 6-7 of the clock_seq_hi_and_reserved to 01
        s[8] = s[13] = s[18] = s[23] = "_";
        var uuid = s.join("");
        return uuid;
    },
    getArrAllKeysByKey: function(arr, key){
        let tempArr = [];
        for (let i = 0; i < arr.length; i++){
            let obj = arr[i];
            tempArr.push(obj[key]);
        }
        return tempArr;
    },
    getObjfromArrByKey: function (arr, key) {
        for (let i = 0; i < arr.length; i++){
            let Obj = arr[i];
            let keys = Object.keys(Obj);
            for (let j = 0; j < keys.length; j++){
                if (Obj[keys[j]] === key){
                    return Obj;
                }
            }
        }
    }
}